<template>
  <v-container fluid>
    <section-header
      :title="$t('settingsPage.authentication')"
      :has-action="false"
    />

    <v-card
      class="py-6 px-6 mt-3"
      rounded="lg"
      elevation="0"
      width="100%"
    >
      <v-row>
        <v-col
          cols="12"
          sm="8"
          offset-sm="2"
          md="6"
          offset-md="3"
        >
          <p class="text-left text-h6">
            {{ $t('authentication.twoFactorAuthentication') }}
          </p>

          <div class="d-flex align-center">
            <p class="text-body-2 mb-0">
              {{ $t('authentication.enableTwoFactorAuthentication') }}
            </p>
            <v-spacer />
            <v-switch
              v-model="enableTwoStepAuthentication"
              color="blue"
              inset
              hide-details
              class="mt-0"
              @change="onChangeAuthentication"
            />
          </div>
        </v-col>
      </v-row>
    </v-card>

    <SetupAuthenticationNav v-model="showSetupNav" />
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

import SectionHeader from '@/components/Form/SectionHeader.vue';
import SetupAuthenticationNav from '@/components/Settings/Authentication/SetupAuthenticationNav.vue';

export default {
  name: 'Authentication',

  components: {
    SectionHeader,
    SetupAuthenticationNav,
  },

  data () {
    return {
      authenticationService: null,
      authenticationType: '',
      enableTwoStepAuthentication: false,
      showSetupNav: false,
    }
  },

  computed: {
    ...mapGetters({ currentUser: 'user/user' }),
  },

  mounted() {
    this.authenticationType = this.currentUser.preferences?.authenticationType || ''
    this.enableTwoStepAuthentication = this.currentUser.preferences?.enableTwoStepAuthentication === undefined ? false : this.currentUser.preferences?.enableTwoStepAuthentication
  },

  methods: {
    async getAuthenticationType() {
      try {
        const response = await this.authenticationService.getAuthenticationType()

        if (!response.data || !response.data.type) {
          return
        }

        this.authenticationType = response.data.type
        this.enableTwoStepAuthentication = true
      } catch (err) {
        this.enableTwoStepAuthentication = false
      }
    },

    onChangeAuthentication() {
      if (!this.enableTwoStepAuthentication) {
        return
      }

      this.showSetupNav = true
    }
  }
}
</script>